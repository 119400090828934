import { useCookies } from 'react-cookie';
import { Navigate } from 'react-router-dom';

interface PrivateProps {
  redirectPath?: string;
}

const PrivateRoute: React.FC<PrivateProps> = ({
  redirectPath = '/app',
  children,
}) => {
  const [cookies] = useCookies();
  const { auth } = cookies;

  if (!!auth) return <>{children}</>;

  return <Navigate to={redirectPath} replace />;
};

export default PrivateRoute;
