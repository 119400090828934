import { ChakraProvider } from '@chakra-ui/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { lazy, Suspense } from 'react';
import { QueryClientProvider, QueryClient } from 'react-query';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import ScrollToTop from './components/ScrollToTop';
import Loader from './components/Loader';
import { theme } from './theme';
import '@fontsource/roboto/500.css';
import AuthRoute from './components/routes/AuthRoute';
import PrivateRoute from './components/routes/PrivateRoute';
import NotFoundRoute from './components/routes/NotFoundRoute';

const AboutApp = lazy(() => import('./pages/AboutApp'));
const Account = lazy(() => import('./pages/auth/user/Account'));
const CustomerService = lazy(() => import('./pages/CustomerService'));
const Eula = lazy(() => import('./pages/Eula'));
const FrequentQuestions = lazy(() => import('./pages/FrequentQuestions'));
const Home = lazy(() => import('./pages/Home'));
const Logout = lazy(() => import('./pages/auth/Logout'));
const NotFound = lazy(() => import('./pages/NotFound'));
const CardPayment = lazy(() => import('./pages/auth/payment/CardPayment'));
const CashPayment = lazy(() => import('./pages/auth/payment/CashPayment'));
const ChangePassword = lazy(() => import('./pages/auth/user/ChangePassword'));
const PaymentMethods = lazy(() => import('./pages/PaymentMethods'));
const Plans = lazy(() => import('./pages/Plans'));
const PrivacyPolicies = lazy(() => import('./pages/PrivacyPolicies'));
const Profile = lazy(() => import('./pages/auth/user/Profile'));
const SignIn = lazy(() => import('./pages/auth/sign-in/SignIn'));
const SignUp = lazy(() => import('./pages/auth/sign-up/SignUp'));
const Subscribe = lazy(() => import('./pages/Subscribe'));
const UserHome = lazy(() => import('./pages/auth/user/UserHome'));

const client = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

const stripePromise = loadStripe('pk_test_Dt4ZBItXSZT1EzmOd8yCxonL');

export const App = () => (
  <QueryClientProvider client={client}>
    <Elements stripe={stripePromise}>
      <ChakraProvider theme={theme}>
        <Suspense fallback={<Loader full />}>
          <Router basename='/app'>
            <ScrollToTop>
              <Routes>
                <Route
                  index
                  element={
                    <AuthRoute>
                      <Home />
                    </AuthRoute>
                  }
                />
                <Route
                  path='/frequent-questions'
                  element={<FrequentQuestions />}
                />
                <Route path='/subscribe' element={<Subscribe />} />
                <Route path='/about-app' element={<AboutApp />} />
                <Route path='/plans' element={<Plans />} />
                <Route path='/payment-methods' element={<PaymentMethods />} />
                <Route path='/privacy-policies' element={<PrivacyPolicies />} />
                <Route path='/eula' element={<Eula />} />
                <Route path='/customer-service' element={<CustomerService />} />
                <Route
                  path='/sign-up'
                  element={
                    <AuthRoute>
                      <SignUp />
                    </AuthRoute>
                  }
                />
                <Route
                  path='/sign-in'
                  element={
                    <AuthRoute>
                      <SignIn />
                    </AuthRoute>
                  }
                />
                <Route
                  path='/logout'
                  element={
                    <AuthRoute>
                      <Logout />
                    </AuthRoute>
                  }
                />
                <Route
                  path='/user/home'
                  element={
                    <PrivateRoute>
                      <UserHome />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/user/profile'
                  element={
                    <PrivateRoute>
                      <Profile />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/user/password'
                  element={
                    <PrivateRoute>
                      <ChangePassword />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/user/payment-methods/cash'
                  element={
                    <PrivateRoute>
                      <CashPayment />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/user/payment-methods/card'
                  element={
                    <PrivateRoute>
                      <CardPayment />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/user/account'
                  element={
                    <PrivateRoute>
                      <Account />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='*'
                  element={
                    <NotFoundRoute>
                      <NotFound />
                    </NotFoundRoute>
                  }
                />
              </Routes>
            </ScrollToTop>
          </Router>
        </Suspense>
      </ChakraProvider>
    </Elements>
  </QueryClientProvider>
);
